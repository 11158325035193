import { UnsafeAction as Action } from '../../interfaces';
import { ScheduleScope } from '../schedule/schedule.model';
import { LoadRequiredShiftsSuccessAction, RequiredShiftModel, RequiredShiftsLoadRequest } from './required-shift.model';
import { OptimizedStoreItemData } from '../../store/schedule/schedule.n.model';

const ACTION_PREFIX = '[RequiredShift]';

export const requiredShiftActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
};

export class RequiredShiftAction {
  static load(requestData): Action {
    return {
      type: requiredShiftActionType.LOAD,
    };
  }

  static loadSuccess(requiredShift, requestData: RequiredShiftsLoadRequest): LoadRequiredShiftsSuccessAction {
    return {
      type: requiredShiftActionType.LOAD_SUCCESS,
      payload: requiredShift,
      requestData,
    };
  }

  static loadFailed(err): Action {
    return {
      type: requiredShiftActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(requiredShiftData, loadingId): Action {
    return {
      type: requiredShiftActionType.ADD,
      payload: { requiredShiftData, loadingId },
    };
  }

  static addSuccess(requiredShiftResponse, loadingId): Action {
    return {
      type: requiredShiftActionType.ADD_SUCCESS,
      payload: { ...requiredShiftResponse, loadingId },
    };
  }

  static update(requiredShiftData, occurrenceId, scope: ScheduleScope, loadingId): Action {
    return {
      type: requiredShiftActionType.UPDATE,
      payload: { requiredShiftData, occurrenceId, scope, loadingId },
    };
  }

  static updateSuccess(scheduleResponse, loadingId, recurring: boolean = false): Action {
    return {
      type: requiredShiftActionType.UPDATE_SUCCESS,
      payload: { ...scheduleResponse, loadingId, recurring },
    };
  }

  static fetch(id: any) {
    return {
      type: requiredShiftActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: requiredShiftActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: requiredShiftActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static remove(occurrenceId: string, scope: ScheduleScope, optimizedData?: OptimizedStoreItemData) {
    return {
      type: requiredShiftActionType.REMOVE,
      payload: { occurrenceId, scope, optimizedData },
    };
  }

  static removeSuccess(id: string) {
    return {
      type: requiredShiftActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }
}
