import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { compose, Store } from '@ngrx/store';
import { addDays, endOfDay, parse, startOfDay } from 'date-fns';
import mapValues from 'lodash-es/mapValues';
import sortBy from 'lodash-es/sortBy';
import uniqueId from 'lodash-es/uniqueId';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import u from 'updeep';

import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { hexToRGB, isColorDark } from '../../../shared/contrast.helper';
import { Totals } from '../../../shared/interfaces';
import { hasAtleastSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { defaultTotal, totalAccumulator } from '../../../shared/total.helper';
import { getAccountSubscription } from '../../account/account.service';
import { PermissionState } from '../../auth/auth.model';
import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedSchedulePeriod } from '../../page-filters/page-filters.helper';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { undo } from '../../shared/undo/undoAction';
import { OptimizedStoreItemData } from '../../store/schedule/schedule.n.model';
import { ContractsLoadRequest } from '../contract/contract.api';
import { DepartmentModel } from '../department/department.model';
import { getDepartmentEntities } from '../department/department.service';
import { getEmployeeEntities } from '../employee/employee.service';
import { LocationModel } from '../location/location.model';
import { getLocationEntities } from '../location/location.service';
import { PermissionOption } from '../permission/permission.model';
import { ScheduleScope } from '../schedule/schedule.model';
import { ShiftModel } from '../shift/shift.model';
import { getShiftEntities } from '../shift/shift.service';
import { TeamModel } from '../team/team.model';
import { getTeamEntities } from '../team/team.service';
import { RequiredShiftAction } from './required-shift.action';
import { RequiredShiftApi } from './required-shift.api';
import { RequiredShiftModel, RequiredShiftsLoadRequest, RequiredShiftState } from './required-shift.model';

export const emptyShift = {
  id: '',
  account_id: null,
  starttime: '00:00:00',
  endtime: '00:00:00',
  break: 0,
  long_name: _('Any shift'),
  name: _('Any'),
  hide_end_time: true,
  color_rgb: hexToRGB('#2399e4'),
  color_is_dark: isColorDark('#2399e4'),
};

@Injectable()
export class RequiredShiftService {
  public constructor(
    private store: Store<AppState>,
    private api: RequiredShiftApi,
  ) {}

  /**
   * Determine the period to update on a save call
   * @param date
   * @returns {Observable<any>}
   */
  private getSavePeriod(date): Observable<{ minDate: string; maxDate: string }> {
    return this.store.select(getSelectedSchedulePeriod).pipe(
      first(),
      map((filterPeriod: ScheduleFilterPeriod) => ({
        minDate: date,
        maxDate: filterPeriod.maxDate < date ? date : filterPeriod.maxDate,
      })),
    );
  }

  public load(requestData: RequiredShiftsLoadRequest, updateStore = true) {
    const check: PermissionCheck = {
      permissions: ['View required shifts'],
      userId: 'me',
      departments: 'any',
    };

    return this.store.select(getPermissionState).pipe(
      map((permissionState) => hasPermission(check, permissionState)),
      switchMap((hasPerm: boolean) => {
        if (hasPerm) {
          return this._load(requestData, updateStore);
        }

        return observableOf(undefined);
      }),
      first(),
    );
  }

  private _load(requestData: ContractsLoadRequest, updateStore: boolean) {
    return this.api.load(requestData, RequiredShiftAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(RequiredShiftAction.loadSuccess(response, requestData));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(RequiredShiftAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(scheduleData, notify = false): Observable<any> {
    return this.getSavePeriod(scheduleData.date).pipe(switchMap((period) => this._add(scheduleData, notify, period)));
  }

  private _add(requiredShiftData, notify = false, period): Observable<any> {
    const loadingId = uniqueId('loading_');

    const action = RequiredShiftAction.add(requiredShiftData, loadingId);

    return this.api
      .add(
        { RequiredShift: requiredShiftData, Notify: notify },
        {
          minDate: period.minDate,
          maxDate: period.maxDate,
        },
        action,
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(RequiredShiftAction.addSuccess(response, loadingId));
        }),
        catchError((response) => {
          this.store.dispatch(undo(action));
          return observableThrowError(response);
        }),
      );
  }

  public update(occurrenceId, requiredShiftData, scope: ScheduleScope, notify = false) {
    return this.getSavePeriod(requiredShiftData.date).pipe(
      switchMap((period) => this._update(occurrenceId, requiredShiftData, scope, notify, period)),
    );
  }

  private _update(occurrenceId, requiredShiftData, scope: ScheduleScope, notify: boolean, period) {
    const loadingId = uniqueId('loading_');

    const action = RequiredShiftAction.update(requiredShiftData, occurrenceId, scope, loadingId);

    return this.api
      .update(
        occurrenceId,
        {
          RequiredShift: requiredShiftData,
          Notify: notify,
        },
        period,
        scope,
        action,
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(RequiredShiftAction.updateSuccess(response, loadingId, scope === 'sequence'));
        }),
        catchError((response) => {
          this.store.dispatch(undo(action));

          return observableThrowError(response);
        }),
      );
  }

  public fetch(occurrenceId) {
    return this.api.fetch(occurrenceId, RequiredShiftAction.fetch(occurrenceId)).pipe(
      tap((response) => {
        this.store.dispatch(RequiredShiftAction.fetchSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(RequiredShiftAction.fetchFailed(occurrenceId, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(occurrenceId, scope: ScheduleScope, optimizedData?: OptimizedStoreItemData) {
    const action = RequiredShiftAction.remove(occurrenceId, scope, optimizedData);

    return this.api.remove(occurrenceId, scope, action).pipe(
      tap((response) => {
        this.store.dispatch(RequiredShiftAction.removeSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));
        return observableThrowError(response);
      }),
    );
  }

  public save(requiredShiftData, scope?: ScheduleScope) {
    if (requiredShiftData.id) {
      return this.update(requiredShiftData.id, requiredShiftData, scope);
    }

    requiredShiftData = u.omit('id', requiredShiftData);

    return this.add(requiredShiftData);
  }
}

export const sortRequiredShifts = (requiredShifts: RequiredShiftModel[]) =>
  sortBy(requiredShifts, ['date', 'starttime']);
export const mapAndSortRequiredShifts = mapAndSortEntities(sortRequiredShifts);

export const getRequiredShiftState = (appState: AppState): RequiredShiftState => appState.orm.requiredshifts;

export const getRequiredShiftIds = compose((state) => state.items, getRequiredShiftState);

export const getRequiredShiftEntities = compose((state) => state.itemsById, getRequiredShiftState);

export const enhanceRequiredShift =
  (state, teamEntities, shiftEntities, departmentEntities, locationEntities) => (requiredShift) => {
    const team = teamEntities[requiredShift.team_id] ? teamEntities[requiredShift.team_id] : ({} as TeamModel);
    const shift = shiftEntities[requiredShift.shift_id]
      ? shiftEntities[requiredShift.shift_id]
      : (emptyShift as ShiftModel);
    const departmentId = requiredShift.department_id;
    const department = departmentEntities[departmentId] ? departmentEntities[departmentId] : ({} as DepartmentModel);
    const locationId = department.location_id;
    const location =
      locationId && !!locationEntities[locationId] ? locationEntities[locationId] : ({} as LocationModel);

    let startDateTime = parse(requiredShift.date + ' ' + requiredShift.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
    let endDateTime = parse(requiredShift.date + ' ' + requiredShift.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

    if (requiredShift.time_settings === 'any') {
      startDateTime = startOfDay(startDateTime);
      endDateTime = endOfDay(endDateTime);
    } else {
      if (endDateTime <= startDateTime) {
        endDateTime = addDays(endDateTime, 1);
      }
    }

    return {
      ...requiredShift,
      department_id: departmentId,
      Team: team,
      Shift: shift,
      department_name: department && department.name ? department.name : '',
      location_name: location && location.name ? location.name : '',
      startDateTime,
      endDateTime,
    };
  };

export const getEnhancedRequiredShiftEntities = createSelector(
  getRequiredShiftState,
  getTeamEntities,
  getShiftEntities,
  getDepartmentEntities,
  getLocationEntities,
  getAccountSubscription,
  (state, teamEntities, shiftEntities, departmentEntities, locationEntities, accountSubscription) => {
    if (!hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, accountSubscription)) {
      return {};
    }

    return mapValues(state.itemsById, (requiredShift) =>
      enhanceRequiredShift(state, teamEntities, shiftEntities, departmentEntities, locationEntities)(requiredShift),
    );
  },
);
export const getEnhancedRequiredShifts = createSelector(
  getRequiredShiftIds,
  getEnhancedRequiredShiftEntities,
  (requiredShiftIds, requiredShiftEntities) => mapAndSortRequiredShifts(requiredShiftIds, requiredShiftEntities),
);

export const getRequiredShifts = createSelector(
  getRequiredShiftIds,
  getRequiredShiftEntities,
  (requiredShiftIds, requiredShiftEntities) => mapAndSortRequiredShifts(requiredShiftIds, requiredShiftEntities),
);

export const getRequiredShift = (occurrenceId: string) =>
  createSelector(getEnhancedRequiredShiftEntities, (entities) => mapEntity(occurrenceId, entities));

export const getRequiredShiftWithSidebar = (occurrenceId: string) =>
  createSelector(getEmployeeEntities, getEnhancedRequiredShiftEntities, (employeeEntities, requiredShiftEntities) => {
    const entity = mapEntity(occurrenceId, requiredShiftEntities);

    /*
    The requiredShift could be gone at this point due to optimistic updates
    changing the id of the requiredShift. In that case simply return `undefined`
    to prevent `entity.created_by` from creating null pointer exceptions.
   */
    if (entity === undefined) {
      return undefined;
    }

    return {
      ...entity,
      CreatedBy: employeeEntities[entity.created_by],
    };
  });

export const hasRequiredShiftPermission =
  (permissions: PermissionOption, permissionState: PermissionState) => (requiredShift: RequiredShiftModel) => {
    const check = {
      permissions,
      userId: 'me',
      departments: requiredShift.department_id,
    };

    return hasPermission(check, permissionState);
  };

export const sumRequiredShifts = (requiredShifts: RequiredShiftModel[]): Totals => {
  if (!requiredShifts || requiredShifts.length === 0) {
    return defaultTotal;
  }

  return requiredShifts.reduce((acc, requiredShift: RequiredShiftModel) => {
    const requiredShiftTotal = {
      hours: parseFloat(requiredShift.total) * parseInt(requiredShift.instances, 10),
      pay: 0,
    };

    return totalAccumulator(acc, requiredShiftTotal);
  }, defaultTotal);
};
