import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { RequiredShiftSchema } from '../../shared/schemas';
import { ScheduleScope } from '../schedule/schedule.model';
import { RequiredShiftsLoadRequest } from './required-shift.model';

@Injectable()
export class RequiredShiftApi {
  private endpoint = 'required_shifts/';

  public constructor(private gateway: ApiGateway) {}

  public load(requestData: RequiredShiftsLoadRequest, dispatchStart?: Action): Observable<any> {
    const searchParams = {
      ...requestData,
    };
    const search = objectToSearchParams(searchParams, ArrayStrategy.MULTIPLE_PARAMS);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart,
      )
      .pipe(
        map((res) => reformatListResponse('RequiredShift', res)),
        map((data) => normalize(data, arrayOf(RequiredShiftSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public add(scheduleData, period, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(period);

    return this.gateway.post(this.endpoint, scheduleData, { params: search }, dispatchStart).pipe(
      map((res) => reformatListResponse('RequiredShift', res)),
      map((data) => normalize(data, arrayOf(RequiredShiftSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public update(occurrenceId, requiredShift, period, scope, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(period);

    return this.gateway
      .put(`${this.endpoint}${occurrenceId}/${scope}`, requiredShift, { params: search }, dispatchStart)
      .pipe(
        map((res) => reformatListResponse('RequiredShift', res)),
        map((data) => normalize(data, arrayOf(RequiredShiftSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public fetch(occurrenceId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + occurrenceId, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('RequiredShift', res)),
      map((data) => normalize(data, RequiredShiftSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(occurrenceId, scope: ScheduleScope, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(`${this.endpoint}${occurrenceId}/${scope}`, undefined, dispatchStart);
  }
}
